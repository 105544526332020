import './Inicio.css';
import logo from "../../img/Logo.svg";
import {motion} from "framer-motion";

import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar';

export default function Inicio() {
  return (
    <>
      <Menu/>
      <Sidebar/>
      <div className="background1">
        <div className="img2"></div>
      </div>
      <div className="background2">
        <div className="logo">
          <img src={logo} className="img" alt="É a logo" />
          <p className="textbot">BOT</p>
        </div>
        <p className="texthelp">PRECISA DE AJUDA?</p>
        <motion.button
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="button"><a href="https://discord.com/api/oauth2/authorize?client_id=1160595654054248459&permissions=8&scope=bot" target="blank" className="button2">ME ADICIONE!</a></motion.button>
      </div>
    </>
  )
}