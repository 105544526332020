import './Novidades.css';
import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar';
import { FaCheck } from "react-icons/fa";

export default function Novidades() {
  return(
    <div className="container">
      <Menu/>
      <Sidebar/>
      <div className="background-news">
        <div className="background-white-news">
          <div className='container-white-news'>
            <div className="container-news">
              <div className="logonews"></div>
            </div>
            <div className='container-textnews'><p>NOVIDADES</p></div>
            <div className='background-post'>
              <div className='container-post container-post3'>
                    <div className='container-user'></div>
                    <div className='container-username'>
                      <p className='username'>Vih's Bot</p>
                      <div className='Verified'><FaCheck /></div>
                    </div>
                    <div className='container-text'>
                      <p className='text-post'><v className="textcolor2">[08/03/2024]: Correção no comando !say:</v>

                      <br />O problema previamente relatado no comando !say foi resolvido.
                      <br /><br /><v className="textcolor2">[08/03/2024]: Aprimoramento no envio de anexos com o comando !say:</v>

                      <br />Agora é possível carregar mais de uma imagem/vídeo ao utilizar o comando !say. Anteriormente, apenas o último anexo era exibido. Com essa atualização, o bot enviará a mensagem com todos os anexos. Lembre-se de que o Discord permite até 10 anexos em uma única mensagem.</p>
                    </div>
                    <div className='container-lastpost'><p className='text-lastpost'>ÚLTIMA POSTAGEM</p></div>
                    <div className='container-successbug'><p className='text-successbug'>PROBLEMA CORRIGIDO</p></div>
                  </div>
              <div className='container-post container-post2'>
                  <div className='container-user'></div>
                  <div className='container-username'>
                    <p className='username'>Vih's Bot</p>
                    <div className='Verified'><FaCheck /></div>
                  </div>
                  <div className='container-text'>
                    <p className='text-post'>Identificamos alguns contratempos em determinados comandos e gostaríamos de informar que estamos dedicados a resolver esses problemas. Esta notificação tem como objetivo garantir que sua experiência conosco permaneça a mais fluida possível. <br /><br />Os comandos atualmente afetados são: <br /><v className="textcolor">!say:</v> Encontramos um erro na verificação da permissão para apagar mensagens do usuário ao executar o comando. <br /><v className="textcolor">!translate:</v> Observamos uma falha na exibição de emojis na embed tutorial deste comando. <br /><v className="textcolor">!welcome:</v> Identificamos um problema na remoção das configurações de boas-vindas. <br /><br />Reconhecemos que esses contratempos podem impactar a utilização do serviço e queremos assegurar que estamos trabalhando para solucionar cada um desses problemas. Estamos comprometidos em evitar qualquer mal funcionamento que possa prejudicar a qualidade do serviço que proporcionamos. <br /><br />Agradecemos pela sua compreensão e paciência enquanto trabalhamos para resolver essas questões. Se você tiver alguma dúvida ou precisa reportar algo mais, não hesite em entrar em contato conosco.</p>
                  </div>
                  <div className='container-reportbug'><p className='text-reportbug'>RELATÓRIO DE BUGS</p></div>
                </div>
              <div className='container-post'>
                <div className='container-user'></div>
                <div className='container-username'>
                  <p className='username'>Vih's Bot</p>
                  <div className='Verified'><FaCheck /></div>
                </div>
                <div className='container-text'>
                  <p className='text-post'>E aí! Chegou nossa página de novidades! Aqui é o lugar onde soltamos sobre tudo que tá rolando. Pode esperar por novidades nos bastidores, comandos novos chegando, aqueles que tão passando por uma manutenção e até os que tão dando umas dorzinhas de cabeça. <br /><br />A ideia é fazer isso tudo de um jeito que a gente se conecte de verdade! Então, aproveite e divirta-se. ;)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}