import './Comandos.css';
import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar';

export default function Comandos() {
  return(
    <div className="container">
      <Menu/>
      <Sidebar/>
      <div className="background-commands">
        <div className="background-white-commands">
          <div className='container-white-commands'>
            <div className="container-commands">
              <div className="logocommands"></div>
            </div>
            <div className='container-text-title-commands'><p className='text-title-commands'>COMANDOS</p></div>
            <div className='container-text-description-commands'><p className='text-description-commands'>Quer aprender um pouco mais sobre os meus comandos, você veio ao lugar certo, aqui você pode aprender tudo e mais um pouco, confira abaixo!</p></div>
            <div className='container-item'>
              <div className='item-commands item-commands-utility'>
                <p className='text-item-commands'>COMANDO DE UTILIDADES</p>
                <p className='text-item-commands'>DESCRIÇÃO</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!help</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Exibe informações de ajuda sobre o bot.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!ping</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Mostra o ping do bot e outras informações relevantes.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!uptime</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Mostra o tempo desde que o bot foi iniciado pela última vez.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!infouser</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Exibe informações sobre um usuário.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!infoserver</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Exibe informações sobre o servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!afk</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Define ou remove o status AFK.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!translate</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Traduz uma mensagem para o idioma desejado.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!level</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Mostra o nível do usuário no bot.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!level top</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Mostra os top 7 usuários com mais nível.</p>
              </div>
              <div className='item-commands'>
                <p className='text-item-commands'>COMANDO DE MODERAÇÃO</p>
                <p className='text-item-commands'>DESCRIÇÃO</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!say</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Faz o bot falar uma mensagem no chat.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!anunciar</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Envia um anúncio em um canal específico.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!clean</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Apaga mensagens do chat.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!warn</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Dar um aviso a um membro.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!mute</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Muta um membro no servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!unmute</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Desmuta um membro no servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!lock</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Bloqueia o acesso a um canal específico.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!unlock</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Desbloqueia o acesso a um canal específico.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!kick</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Expulsar um membro do servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!ban</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Banir um membro do servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!unban</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Remove o banimento de um usuário do servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!banlist</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Exibe a lista de usuários banidos do servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!log</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Define o canal de logs para o servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!autorole</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Define o cargo de autorole para o servidor.</p>
              </div>
              <div className='item-commands item-commands2'>
                <div className='container-newitem'>NOVO</div>
                <div className='newitem'></div>
                <p className='text-item-commands text-item-commands-2'>!disable</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Desative comandos específicos.</p>
              </div>
              <div className='item-commands item-commands2'>
                <div className='container-newitem'>NOVO</div>
                <div className='newitem'></div>
                <p className='text-item-commands text-item-commands-2'>!enable</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Ative novamente os comandos anteriormente desativados.</p>
              </div>
              <div className='item-commands item-commands2'>
                <div className='container-newitem'>NOVO</div>
                <div className='newitem'></div>
                <p className='text-item-commands text-item-commands-2'>!welcome</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Personalize as mensagens de boas-vindas.</p>
              </div>
              <div className='item-commands item-commands-fun'>
                <p className='text-item-commands'>COMANDO DE DIVERSÃO</p>
                <p className='text-item-commands'>DESCRIÇÃO</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!hug</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Abraça outro usuário.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!kiss</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Beija outro usuário.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!slap</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Dá um tapa em outro usuário.</p>
              </div>
              <div className='item-commands item-commands2'>
                <p className='text-item-commands text-item-commands-2'>!piada</p>
                <p className='text-item-commands text-item-commands-2 textdescription-item'>Receba uma piada aleatória.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}