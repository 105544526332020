import './Regras.css';
import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar';

export default function Regras() {
  return(
    <div className="container">
      <Menu/>
      <Sidebar/>
      <div className="background-rules">
        <div className="background-white-rules">
          <div className='container-white-rules'>
            <div className="container-logorules">
              <div className="logorules"></div>
            </div>
            <div className='container-textrules'><p>REGRAS</p></div>
            <div className='container-rules'>
              <p className='text-titlerules'>Estabelecer regras é um passo essencial para prevenir a presença de exploradores e garantir a segurança de nossa comunidade contra indivíduos com intenções ruins.</p>
              <p className='text-rules'><v className='textcolor3'>1- Respeito Mútuo:</v> <br />• Mantenha um ambiente amigável e respeitoso ao usar o Vih's Bot.</p>
              <p className='text-rules'><v className='textcolor3'>2- Sem Spam ou Abuso:</v> <br />• Evite spam e uso indevido dos comandos para manter a harmonia.</p>
              <p className='text-rules'><v className='textcolor3'>3- Respeito aos Limites:</v> <br />• Siga os limites estabelecidos para cada comando, quando aplicável.</p>
              <p className='text-rules'><v className='textcolor3'>4- Sem Exploração de Bugs:</v> <br />• Reporte bugs ao invés de explorá-los para ganho pessoal.</p>
              <p className='text-rules'><v className='textcolor3'>5- Uso indevido de comandos:</v> <br />• Não utilize comandos para atividades ilegais ou antiéticas.</p>
              <p className='text-rules'><v className='textcolor3'>6- Respeito aos Desenvolvedores:</v> <br />• Respeite o trabalho dos desenvolvedores do Vih's Bot e suas decisões.</p>
              <p className='text-rules'><v className='textcolor3'>7- Sem Publicidade Indevida:</v> <br />• Evite utilizar o bot para fins de autopromoção excessiva.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}