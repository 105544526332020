import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Inicio from './pages/Inicio/Inicio.jsx';
import Suporte from './pages/Suporte/Suporte.jsx';
import Comandos from './pages/Comandos/Comandos.jsx';
import Novidades from './pages/Novidades/Novidades.jsx';
import Regras from './pages/Regras/Regras.jsx';
import Sobre from './pages/Sobre/Sobre.jsx';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Inicio/>}></Route>
          <Route path="/suporte" element={<Suporte/>}></Route>
          <Route path="/comandos" element={<Comandos/>}></Route>
          <Route path="/novidades" element={<Novidades/>}></Route>
          <Route path="/regras" element={<Regras/>}></Route>
          <Route path="/sobre" element={<Sobre/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
