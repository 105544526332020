import Sidebar from '../../components/Sidebar';
import './Suporte.css';
import iconvihs from "../../img/Vih's(Icon11) 1.svg";
import Menu from '../../components/Menu/Menu';

export default function Suporte() {
  return(
    <div className="container">
      <Menu/>
      <Sidebar/>
      <div className="background-suport">
        <div className="background-white-suport">
          <div className='container-white-suport'>
            <div className="container-suport">
              <div className="logosuport"></div>
            </div>
            <div className='divhelptext'><p>PRECISA DE AJUDA?</p></div>
            <div className='divtext'><p>Com dúvidas sobre como me utilizar? Não se preocupe, você está no lugar certo! Quer descobrir meus comandos e as últimas novidades? Talvez tenha se enganado de tela, mas não se preocupe, acontece! Estou aqui para ajudar.</p></div>
            <div className='divservertext'><p>SERVIDOR DA COMUNIDADE!</p></div>
            <div className='divtext2'><p>Venha fazer parte do nosso servidor da comunidade, lá você pode pedir ajuda para a nossa equipe, conversar com pessoas novas, ficar por dentro do que estamos planejando para o bot e muito mais! <br /><br /> Basta clicar nesse botão verde escrito "Juntar-se" que está aqui embaixo! 🤗</p></div>
            <div className='containerserver'>
              <p className='textconvite'>VOCÊ FOI CONVIDADO(A) A ENTRAR EM UM SERVIDOR</p>
              <div className='iconserver'><img src={iconvihs} className="iconserver2" alt="Ícone Vih's" /></div>
              <p className='namebot'>Vih's Bot</p>
              <p className='description'>Está com dúvidas ou quer fazer <br /> novos amigos? Junte-se a nós!</p>
              <button className='joinserver'><a href="https://discord.gg/h3UrgDyuzy" target="blank" className='textjoinserver'>Juntar-se</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}