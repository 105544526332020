import React, { useState } from 'react';
import './style.css';
import { FaBars, FaHome, FaHeadset, FaCode, FaNewspaper, FaBook, FaInfoCircle, FaEgg } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  return (
    <>
      <motion.button
        className='sidebar'
        onClick={toggleSidebar}
        whileTap={{ scale: 0.8 }}
      >
        <FaBars />
      </motion.button>
      <AnimatePresence>
        {sidebarVisible && (
          <motion.div
            className='containersidebar'
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.3 }}
          >
            <motion.button
              className='closebutton'
              onClick={closeSidebar}
              whileTap={{ scale: 0.8 }}
            >
              X
            </motion.button>
            <Link to="/">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Início</p>
                <div className='iconsidebar'><FaHome /></div>
              </motion.div>
            </Link>
            <Link to="/suporte">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Suporte</p>
                <div className='iconsidebar'><FaHeadset /></div>
              </motion.div>
            </Link>
            <Link to="/comandos">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Comandos</p>
                <div className='iconsidebar'><FaCode /></div>
              </motion.div>
            </Link>
            <Link to="/novidades">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Novidades</p>
                <div className='iconsidebar'><FaNewspaper /></div>
              </motion.div>
            </Link>
            <Link to="/regras">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Regras</p>
                <div className='iconsidebar'><FaBook/></div>
              </motion.div>
            </Link>
            <Link to="/sobre">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>Sobre</p>
                <div className='iconsidebar'><FaInfoCircle /></div>
              </motion.div>
            </Link>
            <Link to="/event">
              <motion.div className="menu-item-sidebar">
                <p className='name-sidebar'>The Hunt</p>
                <div className='iconsidebar'><FaEgg /></div>
              </motion.div>
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
