import './Menu.css';
import {motion} from "framer-motion";
import {FaHome, FaHeadset, FaCode, FaNewspaper, FaBook, FaInfoCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Link } from 'react-router-dom';

export default function Menu() {
  return(
    <>
      <ReactTooltip place="right" effect="solid" backgroundColor="#ffffff" textColor="#781518"/>
      <div className="menu">
        <Link to="/"><motion.div
          data-tip="Inicio"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaHome/></motion.div></Link>
        <Link to="/suporte"><motion.div 
          data-tip="Suporte"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaHeadset/></motion.div></Link>
        <Link to="/comandos"><motion.div 
          data-tip="Comandos"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaCode/></motion.div></Link>
        <Link to="/novidades"><motion.div 
          data-tip="Novidades"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaNewspaper/></motion.div></Link>
        <Link to="/regras"><motion.div 
          data-tip="Regras"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaBook/></motion.div></Link>
        <Link to="/sobre"><motion.div 
          data-tip="Sobre"
          transition={{ type: "spring", stiffness: 700, damping: 30 }}
          whileHover={{ scale: 1.1, transition: {duration: 0.3}}}
          whileTap={{ scale: 0.9, transition: {duration: 0.3}}} 
          className="menu-item"><FaInfoCircle /></motion.div></Link>
      </div>
    </>
  )
}