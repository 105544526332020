import './Sobre.css';
import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar';
import { FaYoutube, FaInstagram, FaTwitter } from "react-icons/fa";

export default function Sobre() {
  return(
    <div className="container">
      <Menu/>
      <Sidebar/>
      <div className="background-about">
        <div className="background-white-about">
          <div className='container-white-about'>
            <div className="container-about">
              <div className="logoabout"></div>
            </div>
            <div className='containertitle'><p className='titletext'>Olá, eu sou o <v className="textcolor">Vih's!</v></p></div>
            <div className='containerbotdc'><p className='textbotdc'>BOT DISCORD</p></div>
            <div className='containertextdescription'>
              <p className='textdescription'>O <v className="textcolor">Vih's Bot</v> é mais do que um simples bot de Discord, é um companheiro virtual dedicado a tornar o seu servidor ainda mais especial. Desenvolvido com carinho e paixão, o <v className="textcolor">Vih's Bot</v> oferece uma variedade de comandos úteis, divertidos e essenciais para garantir que o seu servidor seja não apenas divertido, mas também seguro.</p>
            </div>
            <div className='containertextdescription2'>
              <p className='textdescription'>O desenvolvimento do <v className="textcolor">Vih's Bot</v> teve início em 2022, marcado por desafios superados ao longo do caminho. Por um tempo, o dono do <v className="textcolor">Vih's Bot</v> optou por desativar temporariamente os serviços em 2023. Agora, em 2024, com um retorno, o <v className="textcolor">Vih's Bot</v> está mais preparado do que nunca para enriquecer diversos servidores, crescer em conjunto com eles e proporcionar experiências incrível. <br /><br />Apesar de sua recente chegada, o <v className="textcolor">Vih's Bot</v> se empenha diariamente para aprimorar suas funcionalidades e trazer constantes novidades. Sua missão é evoluir continuamente, adaptando-se às necessidades dinâmicas dos usuários e oferecendo sempre o melhor em termos de entretenimento e segurança.</p>
            </div>
            <div className='containersocial'>
              <div className='social'><FaYoutube /></div>
              <div className='social'><FaInstagram /></div>
              <div className='social'><FaTwitter /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}